export const APP_NAME = 'gpr';
export const FEEDBACK_EMAIL = 'gprlivefeedback@esgian.com';
export const STORE_VERSION = 1.3;

export const FONT_FAMILY = ['Roboto', 'helvetica', 'Arial', 'sans-serif'].join(',');

export const SUB_SECTIONS = {
  OVERVIEW: 1,
  ANALYTICS: 2,
  DRILLING_ACTIVITIES: 3,
  EQUIPMENT: 4,
  POWER_MGMT: 5
};

export const BASELINE_SUB_SECTIONS = {
  PERIODIC_OVERVIEW: 'Periodic overview',
  DRILLING_ACTIVITIES: 'Drilling activities',
  EQUIPMENT: 'Equipment',
  POWER_MGMT: 'Power mgmt SFOC',
  ANALYTICS: 'Analytics'
};

export const APP_PAGES = {
  DASHBOARD: '/gpr-live',
  RIG_PAGE: '/gpr-live/rig',
  BASELINE_COMP: '/gpr-live/baseline-comp'
};
export const API_ROLLUP = {
  DAY: 'PT24H',
  HOUR: 'PT1H',
  QUARTER: 'PT15M',
  MIN: 'PT1M'
};
export const DISPLAY_UNIT = {
  CO2: 'CO2',
  NOX: 'NOx',
  FUEL: 'Fuel',
  ENERGY: 'Energy'
};

export const WARNINGS = {
  TooManyEng: 'Too many engines running',
  MP_Idle: 'Idle Mud pumps',
  TooManyHPU: 'Too many HPU pumps online',
  DW_AUX_Idle: 'Idle Drawworks'
};
export const WARNINGS_TYPE = {
  TooManyEng: 'TooManyEng',
  MP_Idle: 'MP_Idle',
  TooManyHPU: 'TooManyHPU',
  DW_AUX_Idle: 'DW_AUX_Idle'
};

export const UNIT_LIMIT = {
  CO2: 0.1,
  NOX: null,
  FUEL: 0.1,
  ENERGY: 10000
};

export const BASELINE_TYPE = {
  TIME_WELL: 1,
  TARGET: 2
};

export const DISPLAY_PERIOD = {
  LAST_DAY: 1,
  LAST_WEEK: 2,
  LAST_MONTH: 3,
  LAST_YEAR: 4,
  ALL_TIME: 5
};
export const OPERATION_TYPE = {
  ALL: 1,
  DRILLING: 2,
  HOTEL_AND_UTILITIES: 3
};

export const SFOC_OR_LOAD = {
  SFOC: 1,
  LOAD: 2
};

export const VIEW_BY_TYPE = {
  DAILY: 1,
  HOURLY: 2
};

export const DATA_TYPE = {
  ACCUMULATED: 1,
  DISCRETE: 2
};

export const BASELINE_CHART_TYPE = {
  BY_TIME: 1,
  BY_ACTIVITIES: 2
};

export const TIME_ZONE = {
  RIGS_LOCATION: 'RigsLocation',
  CUSTOM_TIME_ZONE: 'CustomTimeZone'
};

export const TIMESTAMP = 'YYYY-MM-DDTHH:mm:ss';
export const UTC_TIMESTAMP = 'YYYY-MM-DDTHH:mm:ss[Z]';
export const DATE_TIMEZONE_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_MONTH_READABLE_FORMAT = 'YYYY/MMM/DD';
export const TOOLTIP_DATETIME_READABLE = 'DD/MMM/YYYY HH:mm';
export const TOOLTIP_DATE_READABLE = 'DD/MMM/YYYY';

export const MAP_STYLE_DARK_MODE = 'mapbox://styles/mapbox/dark-v11';
export const MAP_STYLE_LIGHT_MODE = 'mapbox://styles/gudbrandschistad/clj5ceqc7001k01qx7wt09lxd';
export const MAP_STYLE_SATELLITE_MODE =
  'mapbox://styles/gudbrandschistad/cljcn8f8k005201qu7h4c9og5';
export const MAPBOX_API_TOKEN =
  'pk.eyJ1IjoiZ3VkYnJhbmRzY2hpc3RhZCIsImEiOiJjbDZjYXkzdmIwcWx2M2JxbHppMjhkZHpqIn0.BUVI0Nirf9dkY6mDJ8hz9w';
