import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  Tooltip
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { useTheme } from '@hooks';
import { defaultTooltip } from '@components/Charts/ChartJsTooltipHelpers';
import { hoverLinePlugin, syncTooltipHiderPlugin, yAxisTitle } from '@components/ChartJsPlugins';
import { Box, Stack } from '@esgian/esgianui';
import { LegendValue, TextWithTooltipIcon } from '@components';
import moment from 'moment/moment';
import Chartjs from '@components/Charts/Chartjs';
import { getStackedBarChartMax } from '@helpers';
import { barHoverPlugin } from '@components/ChartJsPlugins/BarHoverEffect';
import { darkenHexColor } from '@components/Charts/ChartJsColorHelpers';

// Register Chart.js components
ChartJS.register(
  LineElement,
  PointElement,
  LineController,
  BarElement,
  BarController,
  CategoryScale,
  TimeScale,
  LinearScale,
  Filler,
  Tooltip,
  zoomPlugin
);

const handleColumnClick = (chart, activePoint) => {
  if (activePoint.length && chart) {
    const dataIndex = activePoint[0].index;

    // Update the color of the clicked column for each dataset
    chart.data?.datasets?.forEach((dataset, datasetIndex) => {
      const originalColor = dataset.originalBackgroundColor || '#000000'; // Default if not set

      // Ensure backgroundColor is always an array
      if (!Array.isArray(dataset.backgroundColor)) {
        dataset.backgroundColor = dataset.data.map(() => originalColor);
      }

      // Update the background color of the clicked column
      dataset.backgroundColor = dataset.backgroundColor.map((color, i) =>
        i === dataIndex ? darkenHexColor(originalColor, 50) : originalColor
      );
    });

    chart.update();
  }
};
const highlightFirstColumnOnInit = (chart) => {
  // Simulate an active point for the first column
  const firstPoint = [
    {
      datasetIndex: 0, // Assuming you want to highlight the first dataset
      index: 0 // First column (index 0)
    }
  ];

  handleColumnClick(chart, firstPoint);
};
const getOptions = (
  theme,
  data,
  syncRefs,
  max,
  min,
  unit,
  stackedBar,
  type,
  includeTooltipSum,
  hideEmptyTooltipValues,
  hideCurrentTooltip,
  secondaryYAxis,
  customTooltipHandler,
  handleLogClick,
  handlePointClick
) => {
  const {
    palette: {
      text: { primary },
      neutral: { neutral04 },
      opacity: { ten }
    }
  } = theme;
  let options = {
    commonUpdate: true,
    onClick: function (event) {
      if (!handlePointClick) return;
      const { chart } = event;

      const activePoint = chart.getElementsAtEventForMode(
        event,
        'nearest',
        { intersect: false },
        true
      );
      if (activePoint.length) {
        handlePointClick(activePoint[0]);
        handleColumnClick(chart, activePoint);
        syncRefs?.forEach((chartRef) => {
          handleColumnClick(chartRef.current, activePoint);
        });
      }
    },
    beforeDatasetsDraw: () => {
      console.log('asdasd');
    },
    onHover: function (event, elements) {
      if (!handlePointClick) return;
      const chartCanvas = event.native.target;
      chartCanvas.style.cursor = 'pointer';
    },
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 30
      }
    },
    scales: {
      x: {
        min: 0,
        offset: true,
        type: 'category',
        labels: data?.labels ?? [],
        ticks: {
          callback: function (index) {
            let currentDate = moment.utc(this.getLabelForValue(index));
            if (index >= data?.labels.length) {
              currentDate.format('MMM DD');
            }
            let nextDate = moment.utc(this.getLabelForValue(index + 1));
            let rollup = 'hour';
            if (nextDate.diff(currentDate, 'hours') < 1) {
              rollup = 'min';
            }

            if (currentDate.clone().hour() === 0 && rollup === 'hour') {
              return currentDate.format('MMM DD');
            }
            if (currentDate.clone().startOf('day').isSame(currentDate) && rollup === 'min') {
              return currentDate.format('MMM DD');
            }
            return currentDate.format('HH:mm');
          },
          color: primary,
          maxRotation: 45,
          minRotation: 45,
          font: {
            size: 10,
            family: ['Roboto', 'helvetica', 'Arial', 'sans-serif'].join(',')
          }
        },
        grid: {
          display: false,
          drawOnChartArea: false,
          drawTicks: false
        }
      },
      y: {
        stacked: stackedBar,
        border: {
          display: false
        },
        grid: {
          tickLength: 0,
          color: `${neutral04}7F`
        },
        afterFit: function (scaleInstance) {
          let stepSize = `${Math.round((max * 1.5) / 5) * 4}`.length;
          let size = stepSize < 4 ? 4 : stepSize;
          scaleInstance.width = size * 8; // sets the width to 100px
        },
        ticks: {
          color: primary,
          font: {
            size: 10,
            family: ['Roboto', 'helvetica', 'Arial', 'sans-serif'].join(',')
          },
          beginAtZero: true,
          callback: (val) => parseInt(val),
          maxTicksLimit: 6, // +1 for zero
          // stepSize: Math.round(max / 5),
          max: max,
          count: 6,
          precision: 0,
          xMin: 0,
          autoSkip: false
        },
        min: min,
        max: max,
        type: 'linear',
        position: 'left',
        beginAtZero: true
      }
    },
    elements: {
      line: {
        tension: 0
      },
      point: {
        radius: 0
      }
    },
    tooltips: {
      mode: 'index',
      intersect: false
    },
    plugins: {
      tooltip: {
        callbacks: {},
        enabled: false,
        interaction: {
          intersect: false,
          mode: 'index'
        },
        position: 'nearest',
        external: (context) =>
          customTooltipHandler
            ? customTooltipHandler(context, data)
            : defaultTooltip(
                context,
                data,
                theme,
                unit,
                includeTooltipSum,
                hideEmptyTooltipValues,
                hideCurrentTooltip,
                syncRefs,
                handleLogClick
              )
      },
      yAxisTitle: {
        fontColor: primary,
        fontSize: '12px',
        text: unit
      },
      barHoverBackground: {
        syncRefs: syncRefs,
        backgroundColor: ten
      },
      syncTooltipHiderPlugin: {
        syncRefs: syncRefs
      },
      hoverLinePlugin: {
        syncRefs: syncRefs,
        lineColor: primary
      },
      zoom: {
        pan: {
          onPan({ chart }) {
            syncRefs.forEach(({ current }) =>
              current?.zoomScale(
                'x',
                { min: Math.trunc(chart.scales.x.min), max: Math.trunc(chart.scales.x.max) },
                'none'
              )
            );
          },
          enabled: true,
          mode: 'x'
        },
        limits: {
          x: { min: 'original', max: 'original', minRange: 10 }
        },
        zoom: {
          mode: 'x',
          onZoom({ chart }) {
            if (chart.scales.max < 10) {
              chart.scales.x.max = 10;
            }
            syncRefs.forEach(({ current }) =>
              current?.zoomScale(
                'x',
                { min: Math.trunc(chart.scales.x.min), max: Math.trunc(chart.scales.x.max) },
                'none'
              )
            );
          },
          drag: {
            enabled: false
          },
          pinch: {
            enabled: true
          },
          wheel: {
            enabled: true,
            modifierKey: 'ctrl'
          }
        }
      }
    }
  };
  if (secondaryYAxis) {
    options.scales.y1 = secondaryYAxis;
  }
  return options;
};

function CanvasTimeSeriesChart({
  syncRefs,
  id,
  series,
  loading,
  unit,
  maxY,
  minY,
  chartRef,
  hideCurrentTooltip,
  customTooltipHandler,
  handleLogClick,
  handlePointClick,
  hideEmptyTooltipValues,
  height,
  includeTooltipSum,
  enableBarHover,
  secondaryYAxis,
  type,
  stackedBar,
  disableHoverLinePlugin
}) {
  let plugins = [yAxisTitle];
  const [legendActive, setLegendActive] = useState([]);
  const { theme } = useTheme();
  const {
    palette: {
      charts: { sixColorsLine }
    }
  } = theme;

  if (syncRefs.length) {
    plugins.push(syncTooltipHiderPlugin);
  }

  if (!disableHoverLinePlugin) {
    plugins.push(hoverLinePlugin);
  }
  if (enableBarHover) {
    plugins.push(barHoverPlugin);
  }

  const data = useMemo(() => {
    if (!series.length) return { labels: [], datasets: [] };
    return {
      labels: series[0].data?.map(({ x }) => x),
      datasets: series.map((val, i) => ({
        borderColor: sixColorsLine[i % sixColorsLine.length], // Use modulo to avoid index out of bounds
        borderWidth: 2,
        spanGaps: true,
        pointHitRadius: 0,
        pointHoverRadius: 0,
        tension: 0,
        ...val,
        originalBackgroundColor: val.backgroundColor,
        label: val.label ?? '', // Ensure each dataset has a label
        data: val.data ?? [] // Pass data correctly
      }))
    };
  }, [series, sixColorsLine]);

  const options = useMemo(() => {
    if (!data.datasets.length) return null;
    let maxYaxis = maxY;
    if (!maxYaxis) {
      if (stackedBar) {
        maxYaxis = getStackedBarChartMax(series) * 1.2;
      } else {
        maxYaxis = Math.max(...(series.flatMap((s) => s.data.map((d) => d.y)) ?? 1)) * 1.2;
      }
    }
    return getOptions(
      theme,
      data,
      syncRefs,
      maxYaxis,
      minY,
      unit,
      stackedBar,
      type,
      includeTooltipSum,
      hideEmptyTooltipValues,
      hideCurrentTooltip,
      secondaryYAxis,
      customTooltipHandler,
      handleLogClick,
      handlePointClick
    );
  }, [data, theme, syncRefs]);

  const handleLegendClick = (index) => {
    const chart = chartRef.current;
    if (chartRef.current) {
      const meta = chart.getDatasetMeta(index);
      let value = !chart.data.datasets[index].hidden;
      meta.hidden = meta.hidden === null ? value : null;
      if (meta.hidden) {
        setLegendActive([...legendActive, index]);
      } else {
        setLegendActive(() => [...legendActive.filter((v) => v !== index)]);
      }
      chart.update();
    }
  };
  useEffect(() => {
    if (chartRef.current && !!handlePointClick && data.datasets.length) {
      highlightFirstColumnOnInit(chartRef.current);
    }
  }, [chartRef, data]);
  return (
    <Stack sx={{ width: 'auto' }} spacing={1}>
      <Stack direction={'row'} spacing={2} sx={{ rowGap: 'normal' }} useFlexGap flexWrap="wrap">
        {[...data.datasets]
          .filter(({ hidden }) => !hidden)
          .map(({ type, label, legendTooltip, backgroundColor, borderColor }, i) => {
            if (legendTooltip) {
              return (
                <TextWithTooltipIcon
                  key={`${id}-legend-${i}`}
                  iconSize={'16px'}
                  gap={'4px'}
                  label={
                    <LegendValue
                      inactive={legendActive.includes(i)}
                      onClick={() => handleLegendClick(i)}
                      value={label}
                      color={type === 'bar' ? backgroundColor : borderColor}
                    />
                  }
                  tooltipText={legendTooltip}
                />
              );
            }
            return (
              <LegendValue
                inactive={legendActive.includes(i)}
                onClick={() => handleLegendClick(i)}
                key={`${id}-legend-${i}`}
                value={label}
                color={type === 'bar' ? backgroundColor : borderColor}
              />
            );
          })}
      </Stack>
      <Box sx={{ height: height, width: '100%', position: 'relative' }}>
        <Chartjs
          loading={loading}
          plugins={plugins}
          chartRef={chartRef}
          id={id}
          type={type}
          data={data}
          options={options}
        />
      </Box>
    </Stack>
  );
}

CanvasTimeSeriesChart.propTypes = {
  loading: PropTypes.bool,
  stackedBar: PropTypes.bool,
  enableBarHover: PropTypes.bool,
  disableHoverLinePlugin: PropTypes.bool,
  hideEmptyTooltipValues: PropTypes.bool,
  includeTooltipSum: PropTypes.bool,
  type: PropTypes.oneOf(['line', 'bar']),
  hideCurrentTooltip: PropTypes.bool,
  height: PropTypes.string,
  maxY: PropTypes.number,
  secondaryYAxis: PropTypes.object,
  minY: PropTypes.number,
  unit: PropTypes.string,
  series: PropTypes.arrayOf(PropTypes.object),
  id: PropTypes.string,
  chartRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  syncRefs: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })])
  ),
  customTooltipHandler: PropTypes.func,
  handlePointClick: PropTypes.func,
  handleLogClick: PropTypes.func
};

CanvasTimeSeriesChart.defaultProps = {
  height: '330px',
  unit: '',
  maxY: undefined,
  secondaryYAxis: undefined,
  stackedBar: false,
  enableBarHover: false,
  disableHoverLinePlugin: false,
  hideEmptyTooltipValues: false,
  includeTooltipSum: false,
  type: 'line',
  minY: 0,
  loading: false,
  series: [],
  syncRefs: [],
  id: 'timeSeries',
  chartRef: undefined,
  hideCurrentTooltip: false,
  handleLogClick: undefined,
  handlePointClick: undefined,
  customTooltipHandler: undefined
};

export default CanvasTimeSeriesChart;
