import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@esgian/esgianui';
import moment from 'moment-timezone';
import CanvasTimeSeriesChart from '@components/Charts/CanvasTimeSeriesChart/CanvasTimeSeriesChart';
import { UTC_TIMESTAMP } from '@constants';
import ViewBySelect from '@components/Inputs/ViewBySelect/ViewBySelect';

function PeriodicOverviewWithDetailsChart({
  selectedView,
  handleViewChange,
  primarySeries,
  loading,
  handleLogClick,
  primaryUnit,
  detailsTitle,
  selectedColumn,
  setSelectedColumn,
  secondarySeries,
  SecondaryPrimaryChart,
  secondaryPrimaryProps,
  secondaryUnit,
  enableBarHover,
  secondaryStacked,
  disableHoverLinePlugin
}) {
  const mainChartRef = useRef();
  const mainSecChartRef = useRef();
  const secChartRef = useRef();

  const logClick = useCallback((date, unit) => {
    handleLogClick({
      startDate: moment.utc(parseInt(date)).format(UTC_TIMESTAMP),
      unit: unit
    });
  }, []);

  const handleColumnClick = useCallback(
    (val) => {
      const { index } = val;
      const timestamp = primarySeries[0]?.data[index].x;

      setSelectedColumn({
        date: moment.utc(parseInt(timestamp)).format(UTC_TIMESTAMP),
        index: index,
        isLast: index === primarySeries[0].data?.length - 1
      });
    },
    [primarySeries]
  );
  return (
    <Stack spacing={2}>
      <Typography variant={'h6'}>Periodic overview</Typography>
      <CanvasTimeSeriesChart
        syncRefs={[mainSecChartRef]}
        handlePointClick={handleColumnClick}
        enableBarHover
        disableHoverLinePlugin
        height={'310px'}
        hideEmptyTooltipValues
        unit={primaryUnit}
        loading={loading}
        series={primarySeries}
        stackedBar
        chartRef={mainChartRef}
        handleLogClick={(date) => logClick(date, 'day')}
      />
      {SecondaryPrimaryChart && (
        <SecondaryPrimaryChart
          chartRef={mainSecChartRef}
          {...secondaryPrimaryProps}
          syncRefs={[mainChartRef]}
        />
      )}
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography sx={{ letterSpacing: '0.15px' }} variant={'subtitle1'}>
          {detailsTitle} - {`${moment(selectedColumn.date).format('DD/MMM/YYYY')}`}
        </Typography>
        {handleViewChange && (
          <ViewBySelect
            selectedView={selectedView}
            handleChange={handleViewChange}
            disabled={!handleViewChange}
          />
        )}
      </Stack>
      <CanvasTimeSeriesChart
        enableBarHover={enableBarHover}
        disableHoverLinePlugin={disableHoverLinePlugin}
        height={'310px'}
        hideEmptyTooltipValues
        unit={secondaryUnit}
        loading={loading}
        series={secondarySeries}
        stackedBar={secondaryStacked}
        chartRef={secChartRef}
        handleLogClick={(date) => logClick(date, 'hour')}
      />
    </Stack>
  );
}

PeriodicOverviewWithDetailsChart.propTypes = {
  handleLogClick: PropTypes.func.isRequired,
  secondaryUnit: PropTypes.string,
  enableBarHover: PropTypes.bool,
  secondaryStacked: PropTypes.bool,
  disableHoverLinePlugin: PropTypes.bool,
  primaryUnit: PropTypes.string,
  primarySeries: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.number.isRequired,
          y: PropTypes.number
        })
      ).isRequired
    })
  ),
  secondarySeries: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.number.isRequired,
          y: PropTypes.number
        })
      ).isRequired
    })
  ),
  loading: PropTypes.bool,
  selectedView: PropTypes.oneOf(['hour', 'min']),
  handleViewChange: PropTypes.func,
  SecondaryPrimaryChart: PropTypes.elementType,
  secondaryPrimaryProps: PropTypes.object,
  detailsTitle: PropTypes.string,
  setSelectedColumn: PropTypes.func.isRequired,
  selectedColumn: PropTypes.shape({
    date: PropTypes.string,
    index: PropTypes.number,
    isLast: PropTypes.bool
  })
};

PeriodicOverviewWithDetailsChart.defaultProps = {
  primarySeries: [],
  secondarySeries: [],
  loading: false,
  enableBarHover: false,
  secondaryStacked: false,
  disableHoverLinePlugin: false,
  handleViewChange: undefined,
  SecondaryPrimaryChart: undefined,
  secondaryPrimaryProps: undefined,
  detailsTitle: 'Details',
  selectedView: 'hour',
  primaryUnit: '',
  secondaryUnit: '',
  selectedColumn: 0
};

export default PeriodicOverviewWithDetailsChart;
