import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Skeleton, Stack, Typography } from '@esgian/esgianui';
import moment from 'moment-timezone';
import ViewBySelect from '@components/Inputs/ViewBySelect/ViewBySelect';
import { PowerDetailsCharts } from '@components/Charts';
import { fetchPowerMgmtData } from '@api/Rig';
import { API_ROLLUP, DATE_TIMEZONE_FORMAT } from '@constants';
import { useSelector } from 'react-redux';
import { getMainPageRig } from '@store/features/filters/MainPageSlice/MainPageSlice';
import { useTimezone } from '@hooks/useTimezone';
import { useQuery } from '@tanstack/react-query';
import { useTheme } from '@hooks';
import { getRgbaColor } from '@components/Charts/ChartJsColorHelpers';
import { getUnixTimeStamp, getValue } from '@helpers';

function PowerDetailsWrapper({ loading: propsLoading, selectedDate }) {
  const [selectedView, setSelectedView] = useState('hour');
  const selectedRig = useSelector(getMainPageRig);
  const { selectedTimeZone } = useTimezone();
  const { theme } = useTheme();
  const {
    palette: {
      charts: { sixColorsLine, secondaryChartColor }
    }
  } = theme;

  const powerMgmtDetailsQuery = useQuery({
    queryKey: [
      'powerMgmt',
      'details',
      { selectedRig: selectedRig, selectedDate: selectedDate, selectedView: selectedView }
    ],
    enabled: !!selectedDate && !!selectedRig && !!selectedView,
    placeholderData: null,
    queryFn: ({ signal }) => {
      let rollup = selectedView === 'hour' ? API_ROLLUP.HOUR : API_ROLLUP.MIN;
      // Convert the UTC date to the specified timezone
      let startDate = moment.tz(selectedDate.date, selectedTimeZone);
      let endDate = moment.tz(selectedDate.date, selectedTimeZone);
      // Determine the offset and adjust dates if negative
      if (startDate.utcOffset() < 0) {
        startDate.add(1, 'day');
        endDate.add(1, 'day');
      }
      // Set the startDate to the start of selectedDay and endDate to the end of next day
      startDate.startOf('day');
      endDate.endOf('day');

      if (selectedView === 'hour') {
        if (selectedDate.index >= 1) {
          startDate.subtract(1, 'day');
        }
        if (!selectedDate.isLast) {
          endDate.add(1, 'day');
        }
      }

      // Format the dates in the specified timezone format
      startDate = startDate.format(DATE_TIMEZONE_FORMAT);
      if (endDate.isAfter(moment.tz(selectedTimeZone))) {
        endDate = moment.tz(selectedTimeZone);
      }

      endDate = endDate.format(DATE_TIMEZONE_FORMAT);
      return fetchPowerMgmtData(signal, { selectedRig, startDate, endDate }, rollup)
        .then((result) => {
          const { clarifyData, numOfEngines } = result;
          return {
            clarifyData: clarifyData,
            numOfEngines: numOfEngines
          };
        })
        .catch(() => {
          return null;
        });
    }
  });

  const {
    engineLoadData = [],
    engineSfocData = [],
    powerPrimaryData = [],
    powerSecondaryData = [],
    categories = [],
    numOfEngines = 5
  } = useMemo(() => {
    if (!powerMgmtDetailsQuery.data) return {};
    const { clarifyData, numOfEngines } = powerMgmtDetailsQuery.data;
    const { EngOn, P_available, P_peak, P_produced } = clarifyData;

    let load = [];
    let sfoc = [];
    for (let i = 1; i < numOfEngines + 1; i++) {
      const loadSeries = clarifyData[`Eng${i}Lavg`] || {};
      const sfocSeries = clarifyData[`Eng${i}SFOC`] || {};
      load.push({
        label: `Engine ${i}`,
        spanGaps: false,
        type: 'line',
        borderColor: sixColorsLine[i],
        data: Object.keys(loadSeries)?.map((key) => {
          let value = getValue(loadSeries[key], false)?.toFixed(2) ?? null;
          return { x: getUnixTimeStamp(key), y: value };
        })
      });
      sfoc.push({
        label: `Engine ${i}`,
        spanGaps: false,
        type: 'line',
        borderColor: sixColorsLine[i],
        data: Object.keys(sfocSeries)?.map((key) => {
          let value = getValue(sfocSeries[key], false)?.toFixed(2) ?? null;
          return { x: getUnixTimeStamp(key), y: value };
        })
      });
    }

    return {
      engineLoadData: load,
      engineSfocData: sfoc,
      powerPrimaryData: [
        {
          label: 'Power Available',
          order: 2,
          spanGaps: false,
          backgroundColor: getRgbaColor(sixColorsLine[4], 0.4),
          type: 'line',
          fill: 'origin',
          borderColor: sixColorsLine[4],
          data: Object.keys(P_available)?.map((key) => {
            let value = getValue(P_available[key]);
            return { x: getUnixTimeStamp(key), y: value };
          })
        },
        {
          label: 'Average power produced',
          order: 1,
          borderColor: sixColorsLine[1],
          backgroundColor: getRgbaColor(sixColorsLine[1], 0.4),
          fill: 'origin',
          type: 'line',
          data: Object.keys(P_produced)?.map((key) => {
            let value = getValue(P_produced[key]);
            return { x: getUnixTimeStamp(key), y: value };
          })
        },
        {
          label: 'Peak power',
          borderColor: sixColorsLine[3],
          type: 'line',
          data: Object.keys(P_peak)?.map((key) => {
            let value = getValue(P_peak[key]);
            return { x: getUnixTimeStamp(key), y: value };
          })
        }
      ],
      powerSecondaryData: [
        {
          label: 'Engine online',
          borderColor: secondaryChartColor,
          type: 'bar',
          backgroundColor: secondaryChartColor,
          borderSkipped: true,
          data: Object.keys(EngOn)?.map((key) => {
            let value = getValue(EngOn[key]);
            return { x: getUnixTimeStamp(key), y: value };
          })
        }
      ]
    };
  }, [powerMgmtDetailsQuery]);

  return (
    <Stack spacing={2} sx={{ pt: 2 }}>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography variant={'h6'}>
          {propsLoading || powerMgmtDetailsQuery.isFetching ? (
            <Skeleton height={'2em'} width={'10em'} />
          ) : (
            `Power details - ${moment.parseZone(selectedDate?.date).format('DD/MMM/YYYY')}`
          )}
        </Typography>
        <ViewBySelect selectedView={selectedView} handleChange={setSelectedView} />
      </Stack>
      <PowerDetailsCharts
        numberOfEngines={numOfEngines}
        selectedView={selectedView}
        categories={categories}
        loading={propsLoading || powerMgmtDetailsQuery.isFetching}
        engineLoadData={engineLoadData}
        engineSfocData={engineSfocData}
        selectedDate={selectedDate}
        powerPrimaryData={powerPrimaryData}
        powerSecondaryData={powerSecondaryData}
      />
    </Stack>
  );
}

PowerDetailsWrapper.propTypes = {
  selectedDate: PropTypes.object,
  loading: PropTypes.bool
};

PowerDetailsWrapper.defaultProps = {
  selectedDate: null,
  loading: false
};

export default PowerDetailsWrapper;
