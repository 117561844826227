import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import LogDrawer from '@components/Drawers/LogDrawer/LogDrawer';
import moment from 'moment-timezone';
import CanvasTimeSeriesChart from '@components/Charts/CanvasTimeSeriesChart/CanvasTimeSeriesChart';
import { useTheme } from '@hooks';

function PeriodicOverviewChart({ loading, series, yAxisTitle }) {
  const [logInfo, setLogInfo] = useState(null);
  const chartRef = useRef();

  const { theme } = useTheme();

  const {
    palette: {
      text: { primary }
    }
  } = theme;
  const handleLogClick = useCallback(
    (date) => {
      setLogInfo({ startDate: moment.utc(parseInt(date)), unit: 'day' });
    },
    [theme]
  );

  let maxY = Math.max(...(series[0]?.data.map((d) => d.y) ?? [0])) * 1.2;
  return (
    <>
      <CanvasTimeSeriesChart
        disableHoverLinePlugin
        enableBarHover
        chartRef={chartRef}
        maxY={maxY}
        secondaryYAxis={{
          type: 'linear',
          display: true,
          position: 'right',

          ticks: {
            font: {
              size: 10,
              family: ['Roboto', 'helvetica', 'Arial', 'sans-serif'].join(',')
            },
            color: primary
          },
          grid: {
            drawOnChartArea: false // only want the grid lines for one axis to show up
          }
        }}
        id={'periodic-overview'}
        handleLogClick={handleLogClick}
        height={'360px'}
        unit={yAxisTitle}
        series={series}
        loading={loading}
      />
      <LogDrawer handleClose={() => setLogInfo(null)} open={!!logInfo} logInfo={logInfo} />
    </>
  );
}

PeriodicOverviewChart.propTypes = {
  yAxisTitle: PropTypes.string,
  loading: PropTypes.bool,
  series: PropTypes.arrayOf(PropTypes.object)
};
PeriodicOverviewChart.defaultProps = {
  yAxisTitle: '',
  series: [],
  loading: false
};
export default PeriodicOverviewChart;
